// VALUES
$font-ratio : 0.1rem;
$border-radius-main: 10px;

// COLORS
$color-black: #000000;
$color-dark-grey: #2c3e50;
$color-normal-grey: #95a5a6;
$color-light-grey: #ecf0f1;
$color-white: #ffffff;
$color-gold: #e9d189;
$color-brown: #7D5714;
$color-yellow: #E9B621;
$color-yellow-yellow: yellow;
$color-green: #129137;
$color-white-alpha: rgba(255, 255, 255, .6);
$color-yellow-alpha: #E9B62195;
$color-transparent: rgba(255, 255, 255, 0);

// COMPONENTS
$line-gold : 1px solid $color-gold;
$line-yellow-yellow : 1px solid yellow;
$shadow-main: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 $border-radius-main 20px 0 rgba(0, 0, 0, 0.19);
$shadow-gold: 0 1px 2px 0 #e9d18920, 0 $border-radius-main 20px 0 #e9d18919;


body {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 400;
  background-color: $color-white;
  background-image: url('../src/assets/images/main-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600;
}

.login-box {
  padding: 2rem;
  border-radius: $border-radius-main;
  box-shadow: $shadow-main;
  background-color: $color-white;
}

.login-bg {
  background-image: url('../src/assets/images/login-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding: 1rem;
}

.login-sub {
  margin-top: 1rem;
  font-weight: 500;
  color: $color-white;
  text-align: center;
}

.navbar {
  background-color: $color-green !important;
  background-image: url('../src/assets/images/header_rice_bg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar-name {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  color: $color-white;
  font-size: 1rem !important;
}

.content-box {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.content {
  padding: 4rem;
}

.chart-box {
  margin: 1rem !important;
  padding: 1rem;
  text-align: center;
  border-radius: $border-radius-main;
  box-shadow: $shadow-main;
  background-color: $color-white;
}

.chart-desc {
  margin-top: 0;
  text-align: center;
}

.apexcharts-text {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
}

.MuiButton-root {
  // padding-left: 0 !important;
}

.MuiButton-root {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  font-weight: 600 !important;
  // background-color: $color-green !important;
}

.MuiFormLabel-root {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
}

.MuiTypography-root {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
}

.MuiDataGrid-cell {
  font-family: 'Bai Jamjuree' !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
}